import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerNumber } from "store/reducers";
import MaterialButton from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { getPortalAuditReport, getDownloadPortalReport } from "store/actions";
import { cmsLogoAndCollor, cMSResources } from "../../index";
import FetchData from "../../components/Common/FetchData";
import ReportIcon from "@material-ui/icons/Assessment";

import "./AdminReports.scss";

const ReportsView = () => {
  const chartRef = useRef(null);
  const dispatch = useDispatch();
  const getFormattedDate = (daysAgo) => {
    const date = new Date();
    date.setDate(date.getDate() - daysAgo);
    return date.toISOString().slice(0, 10);
  };
  const portalAuditData = useSelector((state) => state.portalAuditReport);
  var initialDaysAgo = 30;
  const [dateFrom, setDateFrom] = useState(getFormattedDate(initialDaysAgo));
  const [dateTo, setDateTo] = useState(getFormattedDate(0));

  useEffect(() => {
    dispatch(getPortalAuditReport(dateFrom, dateTo));
  }, [dispatch, dateFrom, dateTo]);

  useEffect(() => {
    if (portalAuditData) {
      const groupedData = portalAuditData.reduce((acc, item) => {
        if (!acc[item.label]) {
          acc[item.label] = [];
        }
        acc[item.label].push(item);
        return acc;
      }, {});

      const datasets = Object.keys(groupedData).map((label) => {
        const modelData = groupedData[label];
        const labels = modelData.map((data) => data.date);
        const values = modelData.map((data) => data.value ?? 0);

        return {
          model: label,
          labels,
          values,
        };
      });

      renderChart(datasets);
    }
  }, [portalAuditData]);

  const renderChart = (datasets) => {
    if (!chartRef.current) return;

    const formattedDatasets = datasets.map((dataset, index) => {
      const formattedLabels = dataset.labels.map((label) => {
        const date = new Date(label);
        return date.toLocaleDateString("en-US", {
          month: "long",
          day: "numeric",
        });
      });

      const colors = [
        "rgba(0, 118, 129, 0.2)",
        "rgba(255, 99, 132, 0.2)",
        "rgba(54, 162, 235, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(255, 159, 64, 0.2)",
        "rgba(0, 255, 0, 0.2)",
        "rgba(0, 0, 255, 0.2)",
        "rgba(255, 255, 0, 0.2)",
        "rgba(255, 0, 255, 0.2)",
      ];
      const borderColors = [
        "rgba(0, 118, 129, 1)",
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
        "rgba(0, 255, 0, 1)",
        "rgba(0, 0, 255, 1)",
        "rgba(255, 255, 0, 1)",
        "rgba(255, 0, 255, 1)",
      ];

      return {
        label: dataset.model, // Use the equipment model as the label
        data: dataset.values,
        backgroundColor: colors[index % colors.length],
        borderColor: borderColors[index % borderColors.length],
        borderWidth: 1,
      };
    });

    const ctx = chartRef.current.getContext("2d");
    if (window.myChart) {
      window.myChart.destroy();
    }

    window.myChart = new window.Chart(ctx, {
      type: "bar",
      data: {
        labels: datasets[0].labels.map((label) => {
          const date = new Date(label);
          return date.toLocaleDateString("en-US", {
            month: "long",
            day: "numeric",
          });
        }), // Assuming all datasets share the same label dates
        datasets: formattedDatasets,
      },
      options: {
        plugins: {
          legend: {
            labels: {
              font: {
                size: 24,
              },
            },
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    });
  };

  const handleDisplayButtonClick = () => {
    dispatch(getPortalAuditReport(dateFrom, dateTo));
  };

  const handleDownloadButtonClick = () => {
    dispatch(getDownloadPortalReport(dateFrom, dateTo));
  };

  return (
    <div className="order-history order-history-wrapper">
      <div className="order-history-header shape_flex shape_flex--space-between shape_flex--align-center">
        <div className="order-history-header-text shape_flex shape_flex--align-center">
          <ReportIcon
            className="order-history-header-icon"
            color={cmsLogoAndCollor.color}
          />
          <div className="shape_flex shape_flex--direction-column">
            <span className="order-history-header-title">Reports</span>
          </div>
        </div>
        <div className="order-history-controls">
          <div>
            <TextField
              id="date-from"
              label="Date From"
              type="date"
              variant="outlined"
              margin="dense"
              className="date-picker"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ marginRight: "5px" }}
              value={dateFrom}
              onChange={(e) => setDateFrom(e.target.value)}
            />
            <TextField
              id="date-to"
              label="Date To"
              type="date"
              variant="outlined"
              margin="dense"
              className="date-picker"
              InputLabelProps={{
                shrink: true,
              }}
              style={{ marginRight: "5px" }}
              value={dateTo}
              onChange={(e) => setDateTo(e.target.value)}
            />
            <MaterialButton
              variant="contained"
              color="primary"
              style={{ margin: "10px" }}
              onClick={handleDisplayButtonClick}
            >
              Display
            </MaterialButton>

            <MaterialButton
              variant="contained"
              color="primary"
              style={{ margin: "10px" }}
              onClick={handleDownloadButtonClick}
            >
              Download
            </MaterialButton>
          </div>
        </div>
        <span className="order-history-header-subtitle" color="primary"></span>
      </div>
      <div className="chart-container" style={{ height: "350px" }}>
        {portalAuditData && portalAuditData.length > 0 ? (
          <canvas ref={chartRef} />
        ) : (
          <div className="no-data-message" style={{ textAlign: "center" }}>
            {"Nothing to display"}
          </div>
        )}
      </div>
    </div>
  );
};

export default ReportsView;
