import React from "react";
import AccountCogOutline from "components/generic/Icon/AccountCogOutline";
import AccountGroupOutline from "components/generic/Icon/AccountGroupOutline";
import Construction from "components/generic/Icon/Construction";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import docsAndVideosConfig from "Pages/DocsAndVideos/docsAndVideosConfig";
import EventSharpIcon from "@material-ui/icons/EventSharp";
import FolderSharedOutlinedIcon from "@material-ui/icons/FolderSharedOutlined";
import HighLightAlt from "components/generic/Icon/HighLightAlt";
import HomeIcon from "@material-ui/icons/Home";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import OrderHistoryIcon from "components/generic/Icon/OrderHistory";
import PhoneIcon from "@material-ui/icons/Phone";
import RoundedCorner from "components/generic/Icon/RoundedCorner";
import ScheduleIcon from "@material-ui/icons/Schedule";
import SchoolIcon from "@material-ui/icons/School";
import StarSharpIcon from "@material-ui/icons/StarSharp";
import VideoLibrary from "components/generic/Icon/VideoLibrary";
import ViewInAr from "components/generic/Icon/ViewInAr";
import ReportIcon from "@material-ui/icons/Assessment";

// disabled for now. Saved for later use
// import Safety from 'components/generic/Icon/Safety';
// import Software from 'components/generic/Icon/Software';
// import OperationMaintenance from 'components/generic/Icon/OperationMaintenance';
// import Parts from 'components/generic/Icon/Parts';
// import WavesIcon from '@material-ui/icons/Waves';
// import WavesRain from 'components/generic/Icon/WavesRain';

import {
  comingAttractionsConfig,
  docVideoConfig,
  iotDashboardConfig,
  manageCustomersConfig,
  manageUsersConfig,
  partsOrdersConfig,
  purchaseConfig,
  scheduleTrainingConfig,
  serviceHistoryConfig,
  contactUsConfig,
  flowGoHomeConfig,
  customCMSTileConfig1,
  reportsConfig,
} from "../../constants";

const LogoSwitch = ({ navId }) => {
  const renderSwitch = () => {
    switch (navId) {
      case purchaseConfig.purchase.id:
        return <ViewInAr />;
      case iotDashboardConfig.iotDashboard.id:
        return <DashboardOutlinedIcon />;
      case manageUsersConfig.manageUsers.id:
        return <AccountCogOutline />;
      case manageCustomersConfig.manageCustomers.id:
        return <AccountGroupOutline />;
      case scheduleTrainingConfig.scheduleTraining.id:
        return <SchoolIcon />;
      case docsAndVideosConfig.knowledgeBase.id:
        return <RoundedCorner />;
      case docVideoConfig.docVideo.id:
        return <VideoLibrary />;
      case partsOrdersConfig.orderHistory.id:
        return <OrderHistoryIcon />;
      case serviceHistoryConfig.serviceHistory.id:
        return <Construction />;
      case comingAttractionsConfig.comingAttractions.id:
        return <StarSharpIcon />;
      case contactUsConfig.contactUs.id:
        return <PhoneIcon />;
      case reportsConfig.reports.id:
        return <ReportIcon />;
      case customCMSTileConfig1.customCMSTile1.id:
        return <StarSharpIcon />;
      case flowGoHomeConfig.flowGoHome.id:
        return <HomeIcon />;
      case "scheduleService":
        return <EventSharpIcon />;
      case "navigate_next":
        return <NavigateNextIcon />;
      case "open_in_new":
        return <OpenInNewIcon />;
      case "simulate":
        return <HighLightAlt />;
      case "adminReports":
        return <ReportIcon />;
      case "accountInfo":
        return <FolderSharedOutlinedIcon />;
      case "pumpHours":
        return <ScheduleIcon />;
      case "iotDashboard":
        return <DashboardOutlinedIcon />;
      default:
        return "";
    }
  };
  return renderSwitch();
};

export default LogoSwitch;
