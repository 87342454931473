import showModal from "./showModal";
import claims from "./claims";
import accountInfo from "./accountInfo";
import bannerTextConfig from "./bannerTextConfig";
import modals from "./modals";
import currentPage from "./currentPage";
import customerData from "./customerData";
import customerGridData from "./customerGridData";
import inSimulationMode from "./inSimulationMode";
import rolesConfig from "./rolesConfig";
import servicePlan from "./servicePlan";
import showSideNav from "./showSideNav";
import simulatedUserClaims from "./simulatedUserClaims";
import spinner from "./spinner";
import userGridData from "./userGridData";
import vimeo from "./vimeo";
import orders from "./orders";
import services from "./serviceHistory";
import machineSystems from "./machineSystems";
import logoAndColor from "./logoAndColor";
import overwriteResources from "./overwriteResources";
import pumpHoursReport from "./pumpHoursReport";
import portalAuditReport from "./portalAuditReport.js";
import downloadPumpHoursReport from "./downloadPumpHoursReport";
import downloadPortalReport from "./downloadPortalReport";
import anyMachine from "./anyMachine";
import { config } from "../../index";

export default {
  showModal,
  claims,
  accountInfo,
  bannerTextConfig,
  modals,
  currentPage,
  customerData,
  customerGridData,
  inSimulationMode,
  rolesConfig,
  servicePlan,
  showSideNav,
  simulatedUserClaims,
  spinner,
  userGridData,
  vimeo,
  orders,
  services,
  machineSystems,
  logoAndColor,
  overwriteResources,
  pumpHoursReport,
  downloadPumpHoursReport,
  anyMachine,
  portalAuditReport,
  downloadPortalReport,
};

export const getCurrentRoleConfig = (state) => {
  const userRole = JSON.parse(state.claims.extension_CustomerMapping)
    .CustomerMappings[0].Role;
  const simulatedUserRole = state.simulatedUserClaims?.selectedRole;

  if (
    state.inSimulationMode &&
    simulatedUserRole.includes("CustomerTeamAdmin")
  ) {
    const simulatedTeamAdminConfig = {
      ...state.rolesConfig.customerTeamAdmin,
      navItems: {
        ...state.rolesConfig.customerTeamAdmin.navItems,
        purchase: {
          ...state.rolesConfig.customerTeamAdmin.navItems.purchase,
          link: "https://www.flowparts.com",
        },
        iotDashboard: {
          ...state.rolesConfig.customerTeamAdmin.navItems.iotDashboard,
          link: `${config.IoTDashboardURL}?customerNumber=${state.simulatedUserClaims?.customerNumber}&name=${state.simulatedUserClaims?.userName}&selectedRole=${state.simulatedUserClaims?.selectedRole}`,
        },
      },
    };

    return simulatedTeamAdminConfig;
  }

  if (state.inSimulationMode && simulatedUserRole.includes("CustomerUser")) {
    const simulatedUserConfig = {
      ...state.rolesConfig.customerUser,
      navItems: {
        ...state.rolesConfig.customerUser.navItems,
        purchase: {
          ...state.rolesConfig.customerUser.navItems.purchase,
          link: "https://www.flowparts.com",
        },
        iotDashboard: {
          ...state.rolesConfig.customerUser.navItems.iotDashboard,
          link: `${config.IoTDashboardURL}?customerNumber=${state.simulatedUserClaims?.customerNumber}&name=${state.simulatedUserClaims?.userName}&selectedRole=${state.simulatedUserClaims?.selectedRole}`,
        },
      },
    };

    return simulatedUserConfig;
  }

  if (userRole.includes("GlobalAdmin")) {
    return state.rolesConfig.globalAdmin;
  }

  if (userRole.includes("CustomerTeamAdmin")) {
    if (userRole.includes("CustomerUser")) {
      return state.rolesConfig.customerTeamAdminCustomerUser;
    }

    return state.rolesConfig.customerTeamAdmin;
  }

  return state.rolesConfig.customerUser;
};

export const isUserRoleGlobalAdmin = (state) =>
  state.claims
    ? JSON.parse(
        state.claims.extension_CustomerMapping
      ).CustomerMappings[0].Role.includes("GlobalAdmin")
    : false;

export const isUserCustomerUserOnly = (state) => {
  const role = JSON.parse(state.claims.extension_CustomerMapping)
    .CustomerMappings[0].Role;

  return role.length === 1 && role.includes("CustomerUser");
};

export const isUserCustomerUser = (state) => {
  const role = JSON.parse(state.claims.extension_CustomerMapping)
    .CustomerMappings[0].Role;

  return role.includes("CustomerUser");
};

export const isCustomerAdmin = (state) => {
  const role = JSON.parse(state.claims.extension_CustomerMapping)
    .CustomerMappings[0].Role;

  return role.indexOf("CustomerTeamAdmin") !== -1;
};

export const getEmail = (state) => state.claims.emails[0];

export const getOrders = (state) => state.orders;

export const getServices = (state) => state.services;

export const getMachines = (state) => state.machineSystems;

export const getlogoColor = (state) => state.logoAndColor;

export const getoverwriteResources = (state) => state.overwriteResources;

export const getPumpHoursReport = (state) => state.pumpHoursReport;

export const getPortalAuditReport = (state) => state.portalAuditReport;

export const getDownloadPumpHoursReport = (state) =>
  state.downloadPumpHoursReport;

export const getDownloadPortalReport = (state) => state.downloadPumpHoursReport;

export const getAnyMachine = (state) => state.anyMachine;

export const getCustomerNumber = (state) =>
  state.inSimulationMode
    ? state.simulatedUserClaims.customerNumber
    : state.claims.userDetails.number;
